import React, { useState } from 'react';
import {
  Box,
  Button,
  Input,
  VStack,
  FormControl,
  FormLabel,
  Text,
  Progress,
  Flex,
  Heading,
  Divider,
  RadioGroup,
  Radio,
  HStack,
  useToast,
  Collapse,
  IconButton,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import axios from 'axios';

const MotionBox = motion(Box);

const Predict = () => {
  const [articleUrl, setArticleUrl] = useState('');
  const [trueClassification, setTrueClassification] = useState('');
  const [scrapedData, setScrapedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [showResultsButton, setShowResultsButton] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const baseURL = 
      process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
      : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

    try {
      const response = await axios.post(`https://www.apiclickbaitdetector.online/predict`, {
        article_url: articleUrl,
        true_classification: trueClassification,
      });
      setScrapedData(response.data);
      setIsBoxOpen(true); // Open the data display box
      setShowResultsButton(false); // Hide "Show Results" button
      toast({
        title: 'Analysis Complete',
        description: 'The prediction results are ready.',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: 'Error',
        description: 'There was an issue analyzing the URL.',
        status: 'error',
        duration: 4000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
    if (!isBoxOpen) {
      setShowResultsButton(false); // Hide "Show Results" button when opening
    } else {
      setShowResultsButton(true); // Show "Show Results" button when closing
    }
  };

  return (
    <Box bg="gray.50" minH="100vh" p="4">
      {/* Top Input Box */}
      <MotionBox
        bg="white"
        p="6"
        shadow="md"
        borderRadius="lg"
        position="relative"
        width={isBoxOpen ? '90%' : '400px'}
        margin="0 auto"
        animate={{
          width: isBoxOpen ? '90%' : '400px',
        }}
        transition={{ duration: 0.5 }}
      >
        {!isBoxOpen ? (
          <form onSubmit={handleSubmit}>
            <VStack spacing="4" align="stretch">
              <FormControl isRequired>
                <FormLabel>Article URL</FormLabel>
                <Input
                  value={articleUrl}
                  onChange={(e) => setArticleUrl(e.target.value)}
                  placeholder="Enter article URL"
                  focusBorderColor="teal.500"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>
                  Help us improve, put the true classification of this news article
                </FormLabel>
                <RadioGroup
                  value={trueClassification}
                  onChange={(value) => setTrueClassification(value)}
                >
                  <HStack spacing="4">
                    <Radio value="clickbait" colorScheme="teal">
                      Clickbait
                    </Radio>
                    <Radio value="not_clickbait" colorScheme="teal">
                      Not Clickbait
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
              <Button
                type="submit"
                colorScheme="teal"
                isLoading={loading}
                loadingText="Analyzing..."
                width="full"
              >
                Analyze
              </Button>
            </VStack>
          </form>
        ) : (
          <Input
            value={articleUrl}
            readOnly
            width="full"
            borderRadius="lg"
            shadow="sm"
            bg="gray.100"
            textAlign="center"
            onClick={toggleBox}
          />
        )}
      </MotionBox>

      {/* Show Results Button */}
      {showResultsButton && (
        <Flex justify="center" mt="4">
          <Button
            bg="teal"
            variant="solid"
            color="white"
            onClick={() => setIsBoxOpen(true)}
          >
            You have one article tested - Check it
          </Button>
        </Flex>
      )}

      {/* Data Section */}
      <Flex justify="center" mt="6" p="4">
        <Collapse in={isBoxOpen} animateOpacity>
          <MotionBox
            bg="white"
            p="8"
            borderRadius="lg"
            shadow="lg"
            width="90%"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Flex justify="space-between" align="center">
              <Heading size="md" color="teal.600">
                Prediction Results
              </Heading>
              <IconButton
                icon={isBoxOpen ? <FiArrowUp /> : <FiArrowDown />}
                aria-label="Toggle Form"
                onClick={toggleBox}
                variant="ghost"
              />
            </Flex>
            <Divider my="4" />
            {scrapedData ? (
              <VStack spacing="4" align="stretch">
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Title:
                  </Text>
                  <Text>{scrapedData.title || 'N/A'}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600" mb="2">
                    Image:
                  </Text>
                  {scrapedData.imageUrl ? (
                    <Box
                      borderRadius="lg"
                      overflow="hidden"
                      boxShadow="md"
                      border="1px solid"
                      borderColor="gray.200"
                      maxWidth="500px"
                      mx="auto"
                    >
                      <img
                        src={scrapedData.imageUrl}
                        alt="Article"
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                        }}
                      />
                    </Box>
                  ) : (
                    <Text color="gray.500">No image available.</Text>
                  )}
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Description:
                  </Text>
                  <Text>{scrapedData.description || 'N/A'}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Translated Title:
                  </Text>
                  <Text>{scrapedData.translated_title || 'N/A'}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Clickbait:
                  </Text>
                  <Text
                    fontWeight="bold"
                    color={scrapedData.clickbait === 'Yes' ? 'red.500' : 'green.500'}
                  >
                    {scrapedData.clickbait === 'Yes' ? 'Yes' : 'No'}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Clickbait Probability:
                  </Text>
                  <Progress
                    value={scrapedData.clickbait_probability * 100}
                    size="sm"
                    colorScheme="teal"
                    borderRadius="lg"
                  />
                  <Text mt="1">{(scrapedData.clickbait_probability * 100).toFixed(2)}%</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Locations:
                  </Text>
                  <Text>{scrapedData.locations?.join(', ') || 'N/A'}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Is in Kosovo:
                  </Text>
                  <Text>{scrapedData.is_in_kosovo}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Author:
                  </Text>
                  <Text>{scrapedData.author || 'Unknown'}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" color="gray.600">
                    Published Date:
                  </Text>
                  <Text>{scrapedData.postDate || 'Unknown'}</Text>
                </Box>
              </VStack>
            ) : (
              <Text color="gray.600">No data available.</Text>
            )}
          </MotionBox>
        </Collapse>
      </Flex>
    </Box>
  );
};

export default Predict;
