import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Flex,
    VStack,
    Text,
    Checkbox,
    Spinner,
    Image,
    Divider,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/images/logo4.png'; // Replace with your logo path

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSignIn = async (e) => {
        e.preventDefault();
        setLoading(true);

    const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

        try {
            const response = await axios.post(`https://www.apiclickbaitdetector.online/signin`, { email, password });
            if (response.status === 200) {
                localStorage.setItem('user', JSON.stringify(response.data.user));
                navigate('/');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error signing in:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Flex
            minH="100vh"
            align="flex-start" // Align box at the top
            justify="center"
            bgGradient="linear(to-br, #f5f7fa, #eaeef3)"
            position="relative"
            overflow="hidden"
            p={4}
            pt={{ base: '12', md: '20' }} // Increase top padding to lift the box higher
        >
            {/* Background Logos */}
            <Image
                src={logo}
                alt="Background Logo"
                position="absolute"
                top={{ base: '5%', md: '10%' }}
                left={{ base: '10%', md: '5%' }}
                opacity={0.04}
                maxW={{ base: '200px', md: '350px', lg: '450px' }}
                zIndex={0}
            />
            <Image
                src={logo}
                alt="Background Logo"
                position="absolute"
                bottom={{ base: '-10%', md: '10%' }}
                right={{ base: '-10%', md: '5%' }}
                opacity={0.04}
                maxW={{ base: '250px', md: '400px', lg: '500px' }}
                zIndex={0}
            />

            {/* Login Box */}
            <Box
                w={{ base: '100%', sm: '90%', md: '400px' }}
                bg="white"
                borderRadius="xl"
                boxShadow="2xl"
                p={{ base: 6, md: 8 }}
                zIndex={1}
            >
                  <Text
                    fontSize={{ base: 'lg', md: '2xl' }}
                    fontWeight="bold"
                    color="gray.800"
                    textAlign="center"
                    mb={6}
                >
                    Insight Analyze
                </Text>
                {/* Decorative Top Accent */}
                <Box
                    bgGradient="linear(to-r, teal, teal.300)"
                    h="4px"
                    w="100%"
                    mb={4}
                    borderRadius="full"
                />

                <Text
                    fontSize={{ base: 'lg', md: '2xl' }}
                    fontWeight="bold"
                    color="gray.800"
                    textAlign="center"
                    mb={6}
                >
                    Sign in
                </Text>

                <VStack spacing={6} as="form" onSubmit={handleSignIn}>
                    {/* Email Field */}
                    <FormControl id="email" isRequired>
                        <FormLabel fontSize="sm" color="gray.600">
                            Email Address
                        </FormLabel>
                        <Input
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            bg="gray.50"
                            borderColor="gray.300"
                            _hover={{ borderColor: 'gray.400' }}
                            _focus={{ borderColor: 'blue.500', bg: 'white' }}
                            color="gray.800"
                            py={{ base: 3, md: 4 }}
                        />
                    </FormControl>

                    {/* Password Field */}
                    <FormControl id="password" isRequired>
                        <FormLabel fontSize="sm" color="gray.600">
                            Password
                        </FormLabel>
                        <Input
                            type="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            bg="gray.50"
                            borderColor="gray.300"
                            _hover={{ borderColor: 'gray.400' }}
                            _focus={{ borderColor: 'blue.500', bg: 'white' }}
                            color="gray.800"
                            py={{ base: 3, md: 4 }}
                        />
                    </FormControl>

                    {/* Remember Me and Forgot Password */}
                    <Flex justify="space-between" w="100%" fontSize="sm" color="gray.500">
                        <Checkbox colorScheme="blue" size="sm">
                            Remember me
                        </Checkbox>
                        <Text as={RouterLink} to="/forgot-password" color="blue.500">
                            Forgot Password?
                        </Text>
                    </Flex>

                    {/* Login Button */}
                    <Button
                        type="submit"
                        w="full"
                        bg="teal"
                        color="white"
                        _hover={{ bg: 'teal.400' }}
                        isLoading={loading}
                        spinner={<Spinner size="sm" />}
                        py={{ base: 5, md: 6 }}
                    >
                        Login
                    </Button>
                </VStack>

                {/* Divider */}
                <Divider my={6} />

                {/* Register Link */}
                <Text mt={6} fontSize="sm" color="gray.500" textAlign="center">
                    Don&apos;t have an account?{' '}
                    <RouterLink to="/register">
                        <Text as="span" color="blue.500" fontWeight="bold">
                            Register
                        </Text>
                    </RouterLink>
                </Text>
            </Box>
        </Flex>
    );
};

export default SignIn;
