import React, { useEffect, useState } from 'react';
import {
  Box,
  SimpleGrid,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ArticleCard from '../components/organisms/ArticleCard';
import axios from 'axios';
import { SearchIcon } from '@chakra-ui/icons';

const Home = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [triggerSearch, setTriggerSearch] = useState('');
  
  const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;


  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(
          `https://www.apiclickbaitdetector.online/news_articles?query=${triggerSearch}`
        );
        setArticles(response.data);
      } catch (err) {
        setError('Error fetching articles');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [triggerSearch]);

  const handleSearch = () => {
    setLoading(true);
    setTriggerSearch(searchQuery);
  };

  const getClickbaitStatus = (article) =>
    article.clickbait || (article.prediction || '').toLowerCase() === 'clickbait';

  const highlightMatch = (text) => {
    if (!triggerSearch) return text;
    const regex = new RegExp(`(${triggerSearch})`, 'gi');
    return text.split(regex).map((part, index) =>
      part.toLowerCase() === triggerSearch.toLowerCase() ? (
        <span
          key={index}
          style={{
            backgroundColor: '#2971bd',
            color: '#fff',
            fontWeight: 'bold',
            padding: '3px',
            borderRadius: '4px',
          }}
        >
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <Box position="relative" p="5" maxW="1200px" mx="auto" mt="40px">
      <Heading size="lg" mb={4}>
        News Explorer
      </Heading>
      <Divider mb={6} />
      <InputGroup w="400px" mb={6}>
        <Input
          placeholder="Search by title or description..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <InputRightElement>
          <IconButton icon={<SearchIcon />} aria-label="Search" onClick={handleSearch} />
        </InputRightElement>
      </InputGroup>

      {loading && <Text>Loading...</Text>}
      {error && <Text color="red.500">{error}</Text>}

      {!loading && !error && articles.length === 0 && (
        <Text>No articles match your search query.</Text>
      )}

      {!loading && !error && (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="6">
          {articles.map((article) => (
           <Link
           to={`/article/${article.id}`}
           key={article.id}
           state={{
             from: '/home',
             searchQuery: triggerSearch,
             searchResults: articles,
           }}
         >
           <ArticleCard
             title={highlightMatch(article.title)}
             description={highlightMatch(article.description)}
             imageUrl={article.imageUrl}
             prediction={getClickbaitStatus(article) ? 'Clickbait' : 'Not Clickbait'}
             author={article.author}
             postDate={article.postDate}
           />
         </Link>
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default Home;
