import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Badge,
  Spinner,
  useToast,
  IconButton,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Divider,
} from '@chakra-ui/react';
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';
import alertImage from '../assets/images/alert.png'; // Replace with your image path

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const toast = useToast();

  const currentUser = (() => {
    try {
      const storedUser = localStorage.getItem('user');
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error('Failed to parse user from localStorage:', error);
      return null;
    }
  })();

  const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

  useEffect(() => {
    // Detect screen size and show popup for small screens
    if (window.innerWidth < 768) {
      setShowPopup(true);
    }

    const fetchUsers = async () => {
      try {
        const response = await axios.get(`https://www.apiclickbaitdetector.online/get_users`);
        setUsers(response.data);
      } catch (error) {
        toast({
          title: 'Error fetching users',
          description: error.response
            ? `Error: ${error.response.status} - ${error.response.statusText}`
            : error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [toast]);

  if (loading) {
    return (
      <Box textAlign="center" py={10}>
        <Spinner size="xl" />
      </Box>
    );
  }

  const handleEdit = (user) => {
    console.log('Editing user:', user);
  };

  const handleDelete = (user) => {
    console.log('Deleting user:', user);
  };

  return (
    <>
      {/* Popup Modal */}
      <Modal isOpen={showPopup} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Better Experience on Desktop</ModalHeader>
          <ModalBody textAlign="center">
            {/* Scaled Alert Image */}
            <Image
              src={alertImage}
              alt="Desktop Recommended"
              maxW="100px"
              maxH="100px"
              mx="auto"
              mb={4}
            />
            <p>
              For a better experience, please use a desktop or a larger screen to view this
              dashboard.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setShowPopup(false)}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Dashboard Table */}
      <Box maxW="7xl" mx="auto" p={4} bg="white" borderRadius="lg" boxShadow="lg">
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Avatar</Th>
                <Th>Name</Th>
                <Th display={{ base: 'none', md: 'table-cell' }}>Email</Th>
                <Th>Role</Th>
                <Th display={{ base: 'none', md: 'table-cell' }}>Country</Th>
                <Th display={{ base: 'none', md: 'table-cell' }}>Status</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.email}>
                  <Td>
                    <Box textAlign="center">
                      <Avatar
                        name={`${user.first_name} ${user.last_name}`}
                        src={user.profile_picture_url}
                        size="md"
                      />
                      {/* Gradient Line for Logged-In User */}
                      {currentUser?.email === user.email && (
                        <Divider
                          mt={2}
                          bgGradient="linear(to-r, teal, teal.300)"
                          h="2px"
                          borderRadius="lg"
                        />
                      )}
                    </Box>
                  </Td>
                  <Td>{`${user.first_name} ${user.last_name}`}</Td>
                  <Td display={{ base: 'none', md: 'table-cell' }}>{user.email}</Td>
                  <Td>
                    <Badge
                      colorScheme={
                        user.role === 'admin' || user.role === 'administrator'
                          ? 'teal'
                          : 'blue'
                      }
                      borderRadius="4px"
                      px={2}
                    >
                      {user.role === 'admin' || user.role === 'administrator'
                        ? 'Admin'
                        : 'User'}
                    </Badge>
                  </Td>
                  <Td display={{ base: 'none', md: 'table-cell' }}>{user.country}</Td>
                  <Td display={{ base: 'none', md: 'table-cell' }}>
                    {user.is_active ? (
                      <Badge colorScheme="green">Active</Badge>
                    ) : (
                      <Badge colorScheme="red">Inactive</Badge>
                    )}
                  </Td>
                  <Td>
                    {currentUser?.email !== user.email &&
                      user.role !== 'admin' &&
                      user.role !== 'administrator' && (
                        <Stack direction={{ base: 'column', md: 'row' }} spacing={2}>
                          <IconButton
                            icon={<EditIcon />}
                            aria-label="Edit user"
                            colorScheme="blue"
                            size="sm"
                            onClick={() => handleEdit(user)}
                          />
                          <IconButton
                            icon={<DeleteIcon />}
                            aria-label="Delete user"
                            colorScheme="red"
                            size="sm"
                            onClick={() => handleDelete(user)}
                          />
                        </Stack>
                      )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
