import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const toast = useToast();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast({
        title: 'Passwords do not match',
        description: 'Please ensure the new password and confirm password are the same.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

    try {
      const response = await axios.post(
        `https://www.apiclickbaitdetector.online/change_password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        { withCredentials: true }
      );

      toast({
        title: 'Password changed successfully',
        description: 'Your password has been updated.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Optionally redirect or clear the form
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || 'Something went wrong. Please try again.';
      toast({
        title: 'Error changing password',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="md" mx="auto" mt={10} p={6} borderWidth={1} borderRadius="lg" boxShadow="lg" bg="white">
      <form onSubmit={handleChangePassword}>
        <VStack spacing={4}>
          <FormControl id="current_password" isRequired>
            <FormLabel>Current Password</FormLabel>
            <Input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Enter your current password"
            />
          </FormControl>

          <FormControl id="new_password" isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter your new password"
            />
          </FormControl>

          <FormControl id="confirm_password" isRequired>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
            />
          </FormControl>

          <Button type="submit" colorScheme="teal">
            Change Password
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ChangePassword;
