import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Heading,
    useToast,
    Text,
    VStack,
    Link,
    Flex,
} from '@chakra-ui/react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
    const [email, setEmail] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault();

        const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

        if (password !== confirmPassword) {
            toast({
                title: 'Passwords do not match.',
                description: 'Please ensure both passwords are the same.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const response = await axios.post(`https://www.apiclickbaitdetector.online/register`, {
                email,
                first_name,
                last_name,
                password,
            });

            if (response.status === 200) {
                toast({
                    title: 'Registration successful.',
                    description: 'You are now signed in.',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });

                navigate('/');
                window.location.reload();
            }
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Something went wrong.';
            toast({
                title: 'Registration failed.',
                description: errorMessage,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex
            minH="100vh"
            align="flex-start" // Align box higher
            justify="center"
            bgGradient="linear(to-br, #f5f7fa, #eaeef3)"
            position="relative"
            overflow="hidden"
            p={4}
            pt={{ base: '12', md: '20' }} // Adjust top padding for higher box position
        >
            {/* Registration Form Box */}
            <Box
                w={{ base: '100%', sm: '90%', md: '400px' }}
                bg="white"
                borderRadius="xl"
                boxShadow="2xl"
                p={{ base: 6, md: 8 }}
                zIndex={1}
            >
                 <Text
                    fontSize={{ base: 'lg', md: '2xl' }}
                    fontWeight="bold"
                    color="gray.800"
                    textAlign="center"
                    mb={6}
                >
                    Insight Analyze
                </Text>
                {/* Decorative Top Accent */}
                <Box
                    bgGradient="linear(to-r, teal, teal.300)"
                    h="4px"
                    w="100%"
                    mb={4}
                    borderRadius="full"
                />
                <Text
                    fontSize={{ base: 'lg', md: '2xl' }}
                    fontWeight="bold"
                    color="gray.800"
                    textAlign="center"
                    mb={6}
                >
                    Register
                </Text>


                <form onSubmit={handleRegister}>
                    <VStack spacing={4}>
                        {/* First Name Field */}
                        <FormControl id="first_name" isRequired>
                            <FormLabel fontSize="sm" color="gray.600">
                                First Name
                            </FormLabel>
                            <Input
                                type="text"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="Enter your first name"
                                bg="gray.50"
                                borderColor="gray.300"
                                _hover={{ borderColor: 'gray.400' }}
                                _focus={{ borderColor: 'blue.500', bg: 'white' }}
                                color="gray.800"
                                py={{ base: 3, md: 4 }}
                            />
                        </FormControl>

                        {/* Last Name Field */}
                        <FormControl id="last_name" isRequired>
                            <FormLabel fontSize="sm" color="gray.600">
                                Last Name
                            </FormLabel>
                            <Input
                                type="text"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Enter your last name"
                                bg="gray.50"
                                borderColor="gray.300"
                                _hover={{ borderColor: 'gray.400' }}
                                _focus={{ borderColor: 'blue.500', bg: 'white' }}
                                color="gray.800"
                                py={{ base: 3, md: 4 }}
                            />
                        </FormControl>

                        {/* Email Field */}
                        <FormControl id="email" isRequired>
                            <FormLabel fontSize="sm" color="gray.600">
                                Email Address
                            </FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                bg="gray.50"
                                borderColor="gray.300"
                                _hover={{ borderColor: 'gray.400' }}
                                _focus={{ borderColor: 'blue.500', bg: 'white' }}
                                color="gray.800"
                                py={{ base: 3, md: 4 }}
                            />
                        </FormControl>

                        {/* Password Field */}
                        <FormControl id="password" isRequired>
                            <FormLabel fontSize="sm" color="gray.600">
                                Password
                            </FormLabel>
                            <Input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your password"
                                bg="gray.50"
                                borderColor="gray.300"
                                _hover={{ borderColor: 'gray.400' }}
                                _focus={{ borderColor: 'blue.500', bg: 'white' }}
                                color="gray.800"
                                py={{ base: 3, md: 4 }}
                            />
                        </FormControl>

                        {/* Confirm Password Field */}
                        <FormControl id="confirm-password" isRequired>
                            <FormLabel fontSize="sm" color="gray.600">
                                Confirm Password
                            </FormLabel>
                            <Input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm your password"
                                bg="gray.50"
                                borderColor="gray.300"
                                _hover={{ borderColor: 'gray.400' }}
                                _focus={{ borderColor: 'blue.500', bg: 'white' }}
                                color="gray.800"
                                py={{ base: 3, md: 4 }}
                            />
                        </FormControl>

                        {/* Register Button */}
                        <Button
                            type="submit"
                            bg="teal"
                            color="white"
                            width="full"
                            size="lg"
                            _hover={{ bg: 'teal.400' }}
                            py={{ base: 5, md: 6 }}
                        >
                            Register
                        </Button>
                    </VStack>
                </form>

                <Text textAlign="center" mt={4} fontSize="sm" color="gray.500">
                    Already have an account?{' '}
                    <RouterLink to="/sign_in">
                        <Link color="blue.500" fontWeight="bold">
                            Sign In
                        </Link>
                    </RouterLink>
                </Text>
            </Box>
        </Flex>
    );
};

export default Register;
